import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';

// components
import SvgColor from 'src/components/svg-color';
import { useTranslate } from 'src/locales';
import { useDomainContext } from 'src/context/domain-context';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  label: icon('ic_label'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  fund: icon('ic_fund'),
  withdraw: icon('ic_withdraw'),
  transaction: icon('ic_transaction'),
  upload: icon('ic_upload'),
  ico: icon('ic_ico'),
  savingAccounts: icon('ic_saving'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();
  const { domainInfo } = useDomainContext();

  const themeSettings = useMemo(
    () =>
      domainInfo?.internal_brand?.theme_setting
        ? JSON.parse(domainInfo?.internal_brand?.theme_setting)
        : {},
    [domainInfo]
  );

  const data = useMemo(
    () => {
      const config = [
        // ----------------------------------------------------------------------
        {
          subheader: 'OVERVIEW',
          items: [],
        },
      ];

      if (themeSettings?.show_dashboard || themeSettings?.show_dashboard === undefined) config[0]?.items?.splice(0, 0, {
        title: t('Dashboard'),
        path: paths.main.dashboard,
        icon: ICONS.dashboard,
      })
      if (themeSettings?.show_ico || themeSettings?.show_ico === undefined) config[0]?.items?.splice(1, 0, {
        title: t('ICO'),
        path: paths.main.ico,
        icon: ICONS.ico,
      })
      if (themeSettings?.show_saving_account || themeSettings?.show_saving_account === undefined) config[0]?.items?.splice(2, 0, {
        title: t('Saving accounts'),
        path: paths.main.savingAccounts,
        icon: ICONS.savingAccounts,
      })
      if (themeSettings?.show_upload_document || themeSettings?.show_upload_document === undefined) config[0]?.items?.splice(3, 0, {
        title: t('Upload Document'),
        path: paths.main.uploadDocument,
        icon: ICONS.upload,
      })
      if (themeSettings?.show_deposit_fund || themeSettings?.show_deposit_fund === undefined) config[0]?.items?.splice(4, 0, {
        title: t('Deposit Funds'),
        path: paths.main.depositFund,
        icon: ICONS.fund,
      })
      if (themeSettings?.show_widthrawal || themeSettings?.show_widthrawal === undefined) config[0]?.items?.splice(5, 0, { 
        title: t('Withdrawal'), 
        path: paths.main.withdrawal, 
        icon: ICONS.withdraw 
      })
      if (themeSettings?.show_monetary_transaction || themeSettings?.show_monetary_transaction === undefined) config[0]?.items?.splice(6, 0, {
        title: t('Monetary Transaction'),
        path: paths.main.monetaryTransaction,
        icon: ICONS.transaction,
      })
      if (themeSettings?.show_trading_history || themeSettings?.show_widthrawal === undefined) config[0]?.items?.splice(7, 0, {
        title: t('Trading History'),
        path: paths.main.tradingHistory,
        icon: ICONS.analytics,
      })
      if (themeSettings?.show_trading_terminal || themeSettings?.show_trading_terminal === undefined) config[0]?.items?.splice(8, 0, {
        title: t('Trading Terminal'),
        path: paths.main.tradingPortals,
        icon: ICONS.label,
      })
      if (themeSettings?.show_contact_us || themeSettings?.show_contact_us === undefined) config[0]?.items?.splice(9, 0, { 
        title: t('Contact Us'), 
        path: paths.main.contactUs, 
        icon: ICONS.user 
      })

      return config;
      },
    [t, domainInfo]
  );

  return data;
}
